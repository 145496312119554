<template>
  <div class="change-unit-status">
    <div class="card">
      <fd-form @submit="submitForm">
        <modal-body class="p-2">
          <div class="container fluid">
            <h3 class="mt-2 mb-4">Change Status</h3>

            <div class="unit-name mb-2">{{ unit.plot }}, {{ unit.street }}</div>
            <fd-select
              v-model="form.status"
              class="mb-3"
              :options="statusOptions"
              :validators="[validator.required]"
            >
            </fd-select>
          </div>
        </modal-body>
        <!-- Action Footer -->
        <modal-footer class="p-2">
          <div class="col-12 text-right">
            <button type="button" class="btn mr-1" @click="$emit('cancel')">
              Cancel
            </button>
            <button class="btn main">Update</button>
          </div>
        </modal-footer>
      </fd-form>
    </div>
  </div>
</template>

<script>
import { required } from "@/components/GlobalComponents/FormComponents/Validator/rules";
import { projectUnitStatus } from "@/enums";

export default {
  components: {},
  mixins: [],
  props: {
    unit: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      validator: {
        required: required
      },
      statusOptions: this.$mapJsonToArray(projectUnitStatus, (e) => {
        return {
          id: e,
          name: e
        };
      }),

      form: {
        status: ""
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    // Assign the current status
    this.form.status = this.unit.status;
  },
  methods: {
    submitForm() {
      this.$emit("submit", this.unit.id, this.form);
    }
  }
};
</script>

<style lang="scss" scoped>
.change-unit-status {
  .card {
    min-width: 400px;
  }
  .unit-name {
    border: solid 1px $color-secondary;
    color: $color-secondary;
    padding: 6px 12px;
    border-radius: 5px;
    width: fit-content;
  }
}
</style>
